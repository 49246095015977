<template>
  <div>
    <v-overlay :value="showOverlay"></v-overlay>
    <show-model :text="model"></show-model>
    <v-card>
      <v-card-text>
        <CustomSecureBlock
          ref="maintainForm"
          :rows="rows"
          v-model="model"
          id="111"
          @click-submit="submitChangeProfile"
        ></CustomSecureBlock>
      </v-card-text>
    </v-card>

    <!-- <v-card class="mt-10">
      <v-card-text>
        <line-connect></line-connect>
      </v-card-text>
    </v-card> -->

    <div class="mt-10"></div>
    <v-expansion-panels inset>
      <!-- change password -->
      <v-expansion-panel>
        <v-expansion-panel-header>

          <h3 class="title-header">
            <v-icon class="mr-2">mdi-lock </v-icon> Change Password
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <change-password></change-password>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- change password -->
    </v-expansion-panels>
    <!-- <change-password ></change-password> -->
  </div>
</template>

<script>
import ChangePassword from "./change-password";
import LineConnect from "./line-connect";
import CustomSecureBlock from "./../../components/custom/custom-secure-block";

import { createDynamicModel } from "./../../js/custom";
import { InputType, UserRole } from "./../../js/constants";

import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";
import { profile } from "./../../_specific/exportJs";

export default {
  components: {
    "change-password": ChangePassword,
    "line-connect": LineConnect,
    CustomSecureBlock,
  },
  data() {
    return {
      model: {},
      showOverlay: false,
    };
  },
  created() {
    this.getProfile();
    this.initModel();
  },
  methods: {
    getProfile() {
      let url = "/account/profile";
      // url = getFakeUrl(fakeAbbr.profile.get) + url;

      this.showOverlay = true;
      this.$http
        .get(url)
        .then((res) => {
          this.model = res;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    submitChangeProfile() {
      let url = profile.getUrlSubmit(this);

      this.showOverlay = true;
      this.$http
        .post(url, this.model)
        .then((res) => {
          this.model = res;
          this.showAlertSuccess();
          this.$refs.maintainForm.cancelEditMode();
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    initModel() {
      this.model = {};
      createDynamicModel(this, this.model, this.rows);
    },
  },
  computed: {
    rows() {
      return profile.getProfileView(this);
    },
  },
};
</script>
